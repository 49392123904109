@import '../../styles/common/common.scss';

.container {
    text-align: center;
    margin-top: var(--component-unhook-height-small);
    margin-bottom: var(--component-unhook-height-small);

    .title,
    .subtitle {
        padding: 0 var(--bs-gutter-x);
    }

    .title {
        text-transform: uppercase;
        max-width: rem(850px);
        margin: auto;
        font-size: rem(22px);
        line-height: rem(28px);

        @include bp(lg) {
            font-size: rem(24px);
            line-height: rem(34px);
        }

        @include bp(xxl) {
            font-size: rem(32px);
            line-height: rem(46px);
        }
    }

    .subtitle {
        max-width: rem(950px);
        margin: auto;
        font-size: rem(20px);
        line-height: rem(28px);

        @include bp(lg) {
            font-size: rem(22px);
            line-height: rem(30px);
        }
    }
}

.is_dark {
    color: var(--utmb-color-white);
}

.t_small {
    margin-top: rem(25px) !important;

    @include bp(md) {
        margin-top: rem(40px) !important;
    }
}

.t_default {
    margin-top: rem(40px) !important;

    @include bp(md) {
        margin-top: rem(70px) !important;
    }
}

.t_big {
    margin-top: rem(60px) !important;

    @include bp(md) {
        margin-top: rem(100px) !important;
    }
}

.b_small {
    margin-bottom: rem(25px) !important;

    @include bp(md) {
        margin-bottom: rem(40px) !important;
    }
}

.b_default {
    margin-bottom: rem(40px) !important;

    @include bp(md) {
        margin-bottom: rem(70px) !important;
    }
}

.b_big {
    margin-bottom: rem(60px) !important;

    @include bp(md) {
        margin-bottom: rem(100px) !important;
    }
}

// Secondary color for WorldSeries case
.title_ws_color {
    color: var(--utmb-color-ws-secondary);
}
